h1.category-title {
	font-weight: 300;
}

.items-block.events-list .card .mod-intro-text {
	margin-bottom: 5px;
}

.events-list b.no-results {
	font-size: 1.6rem;
}

.events-list b.no-results i {
	color: #ccc;
}

.items-block.cards-list .card h3 {
	/*border-bottom: 1px solid #D9D9D9;*/
	padding: 0;
	margin: 0;
}

.items-block.cards-list .card .card-text {
	border-top: 1px solid #D9D9D9;
	padding-top: 15px;
	margin-top: 10px;
}

.items-list-default .subcategories-list .subcategory > a {
	border-top: 3px solid #7EA73F;
	/*border-bottom: 1px solid rgba(0,0,0,0);*/
	/*border-left: 1px solid rgba(0,0,0,0);*/
	/*border-right: 1px solid rgba(0,0,0,0);*/
	box-shadow: 0 0 1px rgba(126,167,63,1);
}

.items-list-default .subcategories-list .subcategory > a:hover {
	box-shadow: 0 2px 1px rgba(126,167,63,1);
}

.items-list-default .subcategories-list .subcategory > a > i {
	font-size: 40px;
	line-height: 48px;
	color: #5d5d5d;
}

.items-list-default .subcategories-list .subcategory-title {
	margin: 0;
	font-weight: 400;
	line-height: 1.6rem;
}

.items-list-default .subcategories-list .subcategory-title b {
	font-weight: 700;
	display: block;
	color: #5d5d5d;
}

.item-title.page-title {
	position: relative;
	margin-bottom: 25px;
	padding-bottom: 15px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	color: #454545;
	font-size: 2rem;
}

.item-title.page-title small {
	display: block;
	font-size: 1rem;
	color: #b7b7b7;
	margin-top: 5px;
}

.no-title {
    display: block;
    height: 0;
    width: 100%;
    position: relative;
}

.no-title .edit-article-button {
    right: auto;
    left: 0;
}

.item-title.page-title .edit-article-button {
	font-size: 1rem;
	left: auto;
	right: -15px;
}

.item-created-time {
	margin-bottom: 15px;
	font-weight: 500;
	color: #7EA73F;
}

.item-content .item-image {
	/*margin-bottom: 15px;*/
}

.video-list.items-block.cards-list .card h3 {
	border: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.video-list.items-block .card-label:hover {
	cursor: pointer;
	background: #ed1c24;
}

.item-share {
	padding: 15px 0;
	margin-bottom: 15px;
	border-top: 1px solid rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

.item-share a {
	color: #7EA73F !important;
}

.item-share a:hover {
	cursor: pointer;
}

.item-share a > i {
	border: 1px solid #7EA73F;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	margin-right: 10px;
}

.attachments,
.manufacturer {
	list-style: none;
	padding: 0;
	margin-top: 15px;
}

.attachments li,
.manufacturer li {
	display: block;
	margin-right: 5px;
}

.attachments li a,
.manufacturer li a {
	/*border: 1px solid rgba(0,0,0,0.1);*/
	padding: 5px 0;
	font-size: 1rem;
}

.attachments li a i,
.manufacturer li a i {
	margin-right: 5px;
}

.box h3 {
	font-size: 1.3rem;
	line-height: 1.6rem;
}

.box.contact-details h2 {
	font-size: 1.2rem;
	margin: 0;
	padding: 15px;
	background: #7EA73F;
	color: #fff;
}

.box.contact-details .content {
	padding: 15px;
}

.box.contact-details .content p {
	margin-bottom: 5px;
	font-size: 0.8rem;
}

.box.contact-details .content .description {
	margin-bottom: 15px;
}

.box.contact-details .content .data-line {
	padding-bottom: 8px;
}

.box.contact-details .content .data-line i {
	width: 20px;
	color: #7ea73f;
}

.contact-page .map {
	height: 450px;
	overflow: hidden;
}

.category-description {
	margin-bottom: 30px;
}

.item-gallery h4,
.item-video h4,
h4.gallery-title {
	margin-top: 15px;
	border-bottom: 2px solid #f17e2b;
}

.item-gallery h4 span,
.item-video h4 span,
h4.gallery-title span {
	background: #fff;
	font-size: 1rem;
	padding: 0.4rem 1rem;
	display: flex;
	width: fit-content;
	margin-bottom: -7px;
	margin-left: auto;
	margin-right: auto;
	color: #f17e2b;
}

.item-gallery h4 span i,
.item-video h4 span i,
h4.gallery-title span i {
	margin-right: 5px;
	display: none;
}

.item-video .embed-responsive {
	margin: 23px 0;
}

.item-content h3.mod-item-heading {
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	color: #2E6BA9;
}

.item-content ul,
.iten-listing-text ul {
	padding-left: 0;
}

.item-content ul li,
.iten-listing-text ul li {
	font-size: 0.9rem;
	font-weight: 300;
	line-height: 2rem;
	margin-left: 15px;
	list-style: none;
}

.item-content ul li:before,
.iten-listing-text ul li:before  {
	font-family: 'FontAwesome';
	content: '\f111';
	font-size: 8px;
	margin-right: 5px;
	color: #437ba5;
}

.item-content h5 {
	color: #454545;
}

.listing-item h2.item-title {
	font-size: 1.2rem;
	font-weight: 700;
	margin-bottom: 15px;
}

.products_list .listing-item{
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #eee;
}

.products_list .img-col{
	margin-bottom: 30px;
}

.gallery_page .item-gallery h4{
	display: none;
}

.home-contact-text .moduleTitle {
	margin-bottom: 20px;
}

.home-contact-text .custom p {
	font-weight: 400;	
}

.home-contact-text .custom .pre p {
    margin-bottom: 10px;
    font-size: 16px;
}

.home-contact-text hr {
	width: 85%;
    margin-left: 0;
}

.items-block .card.services {
	border-radius: 15px;
    border: 2px solid #437ba5;
}

#k2-container .category-description h2 {
    color: #437ba5;
    font-size: 26px;
    margin-bottom: 30px;
}

#k2-container .category-description ul {
	list-style: none;
}

#k2-container .category-description ul li:before {
    content: "\2022";
    color: #437ba5;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
 
.itemid-174 #k2-container .items-list .card-body {
	text-align: center;
	align-items: center;
	display: flex;
	text-transform: uppercase;
	color: #437ba5;
}

.itemid-174 #k2-container .items-list .card-title {
	margin-bottom: 0;
	width: 100%;
	font-weight: 500;
}