/* The gallery overlay */
#galleryOverlay{
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	opacity:0;
	z-index:100000;
	background-color:#222;
	background-color:rgba(0,0,0,0.8);
	overflow:hidden;
	display:none;
	
	-moz-transition:opacity 1s ease;
	-webkit-transition:opacity 1s ease;
	transition:opacity 1s ease;
}

/* This class will trigger the animation */

#galleryOverlay.visible{
	opacity:1;
}

#gallerySlider{
	height:100%;
	
	left:0;
	top:0;
	
	width:100%;
	white-space: nowrap;
	position:absolute;
	
	-moz-transition:left 0.4s ease;
	-webkit-transition:left 0.4s ease;
	transition:left 0.4s ease;
}

#gallerySlider .placeholder{
	height: 100%;
	line-height: 1px;
	text-align: center;
	width:100%;
	display:inline-block;
}

/* The before element moves the
 * image halfway from the top */

#gallerySlider .placeholder:before{
	content: "";
	display: inline-block;
	height: 50%;
	width: 1px;
	margin-right:-1px;
}

#gallerySlider .placeholder img{
	display: inline-block;
	max-height: 90%;
	max-width: 90%;
	vertical-align: middle;
}

#gallerySlider.rightSpring{
	-moz-animation: rightSpring 0.3s;
	-webkit-animation: rightSpring 0.3s;
}

#gallerySlider.leftSpring{
	-moz-animation: leftSpring 0.3s;
	-webkit-animation: leftSpring 0.3s;
}

/* Firefox Keyframe Animations */

@-moz-keyframes rightSpring{
	0%{		margin-left:0px;}
	50%{	margin-left:-30px;}
	100%{	margin-left:0px;}
}

@-moz-keyframes leftSpring{
	0%{		margin-left:0px;}
	50%{	margin-left:30px;}
	100%{	margin-left:0px;}
}

/* Safari and Chrome Keyframe Animations */

@-webkit-keyframes rightSpring{
	0%{		margin-left:0px;}
	50%{	margin-left:-30px;}
	100%{	margin-left:0px;}
}

@-webkit-keyframes leftSpring{
	0%{		margin-left:0px;}
	50%{	margin-left:30px;}
	100%{	margin-left:0px;}
}

/* Arrows */

#prevArrow,#nextArrow{
	border:none;
	text-decoration:none;
	opacity:0.5;
	cursor:pointer;
	position:absolute;
	width:43px;
	height:36px;
	
	top:50%;
	margin-top:-18px;
	
	-moz-transition:opacity 0.2s ease;
	-webkit-transition:opacity 0.2s ease;
	transition:opacity 0.2s ease;
}

#prevArrow:before {
        font-family: 'FontAwesome';
        content: '\f104';
        color: #fff;
        font-size: 36px;
}

#nextArrow:before {
        font-family: 'FontAwesome';
        content: '\f105';
        color: #fff;
        font-size: 35px;
}

#prevArrow:hover, #nextArrow:hover{
	opacity:1;
}

#prevArrow{
	background-position:left top;
	left:40px;
}

#nextArrow{
	background-position:right top;
	right:40px;
        text-align: right;
}

.gallery-container .image {
        margin: 15px 0;
}

.touch-trigger:hover {
	cursor: pointer;
}

#gallery-image-title {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 15px;
	background: #000;
	color: #fff;
}

#gallery-image-title .title {
	float: left;
}

#gallery-image-title .pages {
	float: right;
}

.gallery-container a.touch {
	display: block;
	position: relative;
}

.gallery-container a.touch .card-img-overlay {
	opacity: 0;
	background: rgba(0,0,0,0.4);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.gallery-container a.touch:hover .card-img-overlay {
	opacity: 1;
}